var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Navegador)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('v-app-bar',{staticClass:"elevation-0 navegador",attrs:{"id":"navegador","color":"primario","height":"100","app":""}},[_c('v-row',{staticClass:"espaciado align-items-center mt-0 justify-center align-center",staticStyle:{"margin-bottom":"18px"}},[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"ml-1 mt-4",attrs:{"contain":"","src":_vm.Navegador.logo,"flat":"","height":"75"}})],1),_c('v-col',{staticStyle:{"margin-left":"80px","margin-right":"80px"}},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"align-items-center justify-center pr-5",staticStyle:{"margin-top":"8px"}},_vm._l((_vm.Navegador.rutas),function(ref,i){
var ruta = ref.ruta;
var texto = ref.texto;
return _c('v-col',{key:i,staticStyle:{"text-align":"center","width":"50%!important"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"bloque-ruta",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.dirigir_a(ruta)}}},[_c('v-row',{staticClass:"align-items-center justify-center",staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(texto))])]),_c('v-row',{staticClass:"align-items-center justify-center",staticStyle:{"margin-top":"12px"}},[(hover)?_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid #b6e8ef"}}):_c('div',{staticClass:"triangulo",staticStyle:{"width":"0","height":"0","border-left":"7.07px solid transparent","border-right":"7.07px solid transparent","border-bottom":"10px solid transparent"}}),(hover)?_c('div',{staticClass:"linea celeste",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}}):_c('div',{staticClass:"linea transparent",staticStyle:{"width":"100%","height":"3px","border-radius":"0%"}})])],1)]}}],null,true)})],1)}),1)],1)],1)],1),_c('v-col',[_c('v-row',{staticStyle:{"margin-top":"8px"}},_vm._l((_vm.Pie_de_página.redes_sociales.filter(function (item, i){ return i<4; })),function(ref,i){
var enlace = ref.enlace;
var icono = ref.icono;
return _c('v-btn',{key:i,staticClass:"white mr-6",attrs:{"icon":"","dark":"","small":""},on:{"click":function($event){return _vm.abrir_enlace(enlace)}}},[_c('v-icon',{staticClass:"primario--text",attrs:{"dark":"","large":""}},[_vm._v(_vm._s(icono))])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"d-md-none"},[(_vm.Navegador)?_c('v-app-bar',{staticClass:"pr-5",attrs:{"app":"","color":"primario","height":"100"}},[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"elevation-0",attrs:{"contain":"","src":_vm.Navegador.logo,"height":"60","width":"110"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"color":"azul","large":""}},[_vm._v("fas fa-bars")])],1)],1):_vm._e(),_c('v-navigation-drawer',{staticClass:"primario",attrs:{"temporary":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{staticClass:"justify-center"},[_c('router-link',{attrs:{"to":{name: "inicio"}}},[_c('v-img',{staticClass:"justify-center",attrs:{"height":"85","width":"120","src":_vm.Navegador.logo,"contain":""}})],1)],1),_vm._l((_vm.Navegador.rutas),function(ref,i){
var texto = ref.texto;
var ruta = ref.ruta;
var icono = ref.icono;
return _c('v-list-item',{key:i,staticClass:"my-1",on:{"click":function($event){_vm.ruta_actual!=ruta ? _vm.dirigir_a(ruta) : _vm.drawer=false}}},[_c('v-container',[_c('v-row',{staticClass:"align-items-center justify-center"},[_c('span',{staticClass:"font-weight-light mt-4 ml-3",style:(_vm.ruta_actual===ruta ? "color: #45F3FF" : "color: white"),domProps:{"innerHTML":_vm._s(texto)}})]),_c('v-row',{staticClass:"justify-center mt-6"},[_c('v-divider',{staticClass:"white",staticStyle:{"max-width":"90%"}})],1)],1)],1)}),_c('v-list-item',{staticClass:"justify-center mt-9"},[_c('v-container',[_c('v-row',{staticClass:"align-items-center justify-center"},[_c('span',{staticClass:"white--text"},[_vm._v("Llámanos al")])]),_c('v-row',{staticClass:"align-items-center justify-center"},[_c('strong',{staticClass:"white--text"},[_vm._v("991 250 606")])])],1)],1)],2)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }