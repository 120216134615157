<template lang="pug">
div(v-if='Navegador')
  //-Pc
  .d-none.d-md-block
    v-app-bar#navegador(  color='primario'  height='100' app).elevation-0.navegador
      
        v-row.espaciado.align-items-center.mt-0(style='margin-bottom: 18px').justify-center.align-center
          router-link(:to='{name: "inicio"}')
            v-img(contain :src='Navegador.logo' flat height=75 ).ml-1.mt-4
          v-col(style='margin-left: 80px; margin-right: 80px')
              v-row
                v-col
                  v-row(style='margin-top: 8px').align-items-center.justify-center.pr-5
                    v-col(v-for='{ruta, texto}, i in Navegador.rutas' :key='i' style='text-align: center; width: 50%!important')
                      v-hover(v-slot="{ hover }")
                        .bloque-ruta(style='cursor: pointer;' @click='dirigir_a(ruta)')
                          v-row(style='text-align: center').align-items-center.justify-center
                            span.white--text {{texto}}
                          v-row(style='margin-top: 12px;').align-items-center.justify-center
                            .triangulo(v-if='hover' style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid #b6e8ef')
                            .triangulo(v-else style='width: 0; height: 0; border-left: 7.07px solid transparent; border-right: 7.07px solid transparent; border-bottom: 10px solid transparent')
                            .linea.celeste(v-if='hover' style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)
                            .linea.transparent(v-else style=`width: ${(i===3) ? '10%' : '100%'}; height: 3px; border-radius: 0%`)

                    
                //- .bloque
                //-   .bloque-circulo.white
                //-       v-img(:src='Navegador.llamanos_icono').ml-1
                //-   .bloque-blanco.white  
                //-     .texto(style='padding-left: 25px; padding-top: 22px; position: absolute; line-height: 16px').justify-center.align-center
                //-         v-row.mt-0.mb-0.pt-0.pb-0
                //-           strong.primario--text.font-weight-light Llámanos al
                        //- v-row.mt-0.mb-0.pt-0.pb-0
                        //-   strong.primario--text 991 250 606 
          v-col
            v-row(style='margin-top: 8px')
              v-btn(v-for='{enlace, icono}, i in Pie_de_página.redes_sociales.filter((item, i)=>i<4)' icon dark small :key='i' @click='abrir_enlace(enlace)').white.mr-6
                v-icon(dark large).primario--text {{icono}}
  //- movil
  .d-md-none
    v-app-bar( app   v-if='Navegador'  color='primario'   height='100' ).pr-5
      router-link(:to='{name: "inicio"}')
        v-img(contain :src='Navegador.logo'  height=60 width=110).elevation-0
      v-spacer
      v-btn(icon  @click='drawer = !drawer' dark)
        v-icon(color='azul' large) fas fa-bars
    v-navigation-drawer( temporary v-model="drawer" app).primario
      v-list
        v-list-item.justify-center
          router-link(:to='{name: "inicio"}')
            v-img( height=85 width=120 :src='Navegador.logo' contain).justify-center
        v-list-item(v-for='{texto, ruta, icono}, i in Navegador.rutas' :key='i' 
        @click='ruta_actual!=ruta ? dirigir_a(ruta) : drawer=false').my-1
          v-container
            v-row.align-items-center.justify-center
              span(v-html='texto' :style='ruta_actual===ruta ? "color: #45F3FF" : "color: white"').font-weight-light.mt-4.ml-3
            v-row.justify-center.mt-6
              v-divider(style='max-width: 90%').white
        v-list-item.justify-center.mt-9
          v-container
            v-row.align-items-center.justify-center
              span.white--text Llámanos al
            v-row.align-items-center.justify-center
              strong.white--text 991 250 606
</template>
<script>
export default {
  data: ()=>({
    drawer: false,
  }),
  computed: {
    ruta_actual(){
      return this.$route.name
    },
  },
  methods: {
    abrir_enlace(enlace){
      window.open(enlace, '_blank')
    }
  },
}
</script>
<style lang="sass" scoped>
.col-X 
    flex: 0 0 100%
    max-width: 80%

.espaciado
  padding-left: 250px
  padding-right: 20px
.navegador
  position: sticky
.bloque
  position: absolute
  top: 100%
  right: 0
  .bloque-blanco
    top: 0
    width: 220px
    height: 75px
    float: left
    margin-left: -40px
    position: relative
    z-index: -1
    padding-left: 30px
  .bloque-circulo
    position: relative
    top: 0
    border-radius: 50%
    width: 74px
    height: 75px
    float: left
</style>